<template>
    <div class="p-project-detail" v-loading="!ready">
        <div class="hd">
            <div class="page-title">
                项目详情<span v-if="!!name" v-text="' - ' + name"></span>
            </div>
        </div>
        <div class="bd">
            <el-tabs value="info" type="border-card">
                <el-tab-pane label="资料" name="info">
                    <div
                        class="group"
                        v-for="group of fields"
                        :key="group.group.key"
                    >
                        <div class="group-name">
                            <span v-text="group.group.name"></span>
                        </div>
                        <div class="group-wrap">
                            <el-row>
                                <el-col
                                    :span="8"
                                    v-for="field of group.list"
                                    :key="field.key"
                                    class="field"
                                >
                                    <div class="field-name">
                                        <span v-text="field.name"></span>：
                                    </div>
                                    <div class="field-value">
                                        <div v-show="!field.isEditing">
                                            <span v-if="field.link">
                                                <a :href="field.link">{{
                                                    field.valueStr
                                                }}</a>
                                            </span>
                                            <span v-else>{{
                                                field.valueStr
                                            }}</span>
                                        </div>
                                        <div v-show="field.isEditing">
                                            <el-input
                                                v-model="field.newValue"
                                                :placeholder="field.tip"
                                                v-if="field.type === 'string'"
                                            ></el-input>
                                            <el-date-picker
                                                v-if="field.type === 'month'"
                                                v-model="field.newValue"
                                                type="month"
                                                placeholder="选择月份"
                                            >
                                            </el-date-picker>
                                            <el-date-picker
                                                v-if="field.type === 'date'"
                                                v-model="field.newValue"
                                                type="date"
                                                placeholder="选择日期"
                                            >
                                            </el-date-picker>
                                            <el-date-picker
                                                v-if="field.type === 'datetime'"
                                                v-model="field.newValue"
                                                type="datetime"
                                                placeholder="选择日期时间"
                                            >
                                            </el-date-picker>
                                            <el-select
                                                v-if="field.type === 'team'"
                                                v-model="field.newValue"
                                            >
                                                <el-option
                                                    v-for="team of team_list"
                                                    :key="team.value"
                                                    :value="team.value"
                                                    :label="team.label"
                                                ></el-option>
                                            </el-select>
                                            <el-select
                                                v-if="field.type === 'member'"
                                                v-model="field.newValue"
                                                :filterable="true"
                                            >
                                                <el-option
                                                    v-for="member of member_list"
                                                    :key="member.value"
                                                    :value="member.value"
                                                    :label="member.label"
                                                ></el-option>
                                            </el-select>
                                            <el-select
                                                v-if="field.type === 'contract'"
                                                v-model="field.newValue"
                                                :filterable="true"
                                            >
                                                <el-option
                                                    v-for="contract of contract_list"
                                                    :key="contract.value"
                                                    :value="contract.value"
                                                    :label="contract.label"
                                                ></el-option>
                                            </el-select>
                                            <el-select
                                                v-if="
                                                    field.type ===
                                                    'project_status'
                                                "
                                                v-model="field.newValue"
                                            >
                                                <el-option
                                                    v-for="status of projectStatusList"
                                                    :key="status.value"
                                                    :value="status.value"
                                                    :label="status.text"
                                                ></el-option>
                                            </el-select>
                                            <el-select
                                                v-if="field.type === 'yes_no'"
                                                v-model="field.newValue"
                                            >
                                                <el-option
                                                    :value="'Y'"
                                                    label="Yes"
                                                ></el-option>
                                                <el-option
                                                    :value="'N'"
                                                    label="No"
                                                ></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                    <div
                                        class="field-icons"
                                        v-show="
                                            field.editable && !field.isEditing
                                        "
                                    >
                                        <i
                                            class="el-icon-edit"
                                            @click="edit(field)"
                                        ></i>
                                    </div>
                                    <div
                                        v-show="field.isEditing"
                                        style="min-width: 122px"
                                    >
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            @click="save(field)"
                                            >保存</el-button
                                        >
                                        <el-button
                                            size="mini"
                                            @click="cancel(field)"
                                            >取消</el-button
                                        >
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="备注" name="remarks" v-loading="isLoading">
                    <div class="remarks-form">
                        <el-form>
                            <el-form-item>
                                <el-input
                                    type="textarea"
                                    :rows="3"
                                    placeholder="请输入内容"
                                    v-model="remarksForm.remark"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="addRemark"
                                    >添加</el-button
                                >
                                <el-button
                                    type="warn"
                                    @click="cleanRemark"
                                    v-if="$store.state.user.simple_role === 1"
                                    >清理</el-button
                                >
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="remarks-list">
                        <el-table :data="remarks">
                            <el-table-column>
                                <template scope="scope">
                                    <div>
                                        <span
                                            class="remark-account"
                                            v-text="
                                                memberNameWithEmail(
                                                    scope.row.account_id
                                                )
                                            "
                                        ></span>
                                        &nbsp;
                                        <span
                                            class="remark-created_at"
                                            v-text="scope.row.created_at"
                                        ></span>
                                    </div>
                                    <div
                                        v-html="toHtml(scope.row.remark)"
                                    ></div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="附件" name="attachments">
                    <el-upload
                        class="upload-demo"
                        drag
                        action="/attachment/upload"
                        :headers="uploadHeaders"
                        :data="uploadData"
                        :on-success="onUploadSuccess"
                        :on-error="onUploadError"
                        accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.zip,.tar,.tar.gz,.gz,.rar,.pdf,image/*,audio/*,video/*,.json,.css,.js"
                    >
                        <!-- multiple -->
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            可拖放，<em>可点击</em>
                        </div>
                        <div class="el-upload__tip" slot="tip">
                            支持图片、音频、视频、压缩文件、office文档、pdf
                        </div>
                    </el-upload>
                    <div class="attachment-list">
                        <div
                            class="attachment-item"
                            v-for="attachment of attachments"
                            :key="attachment.uid"
                        >
                            <div class="attachment-date first">
                                <span v-text="attachment.created_at"></span>
                            </div>
                            <div class="attachment-account">
                                <span
                                    v-text="
                                        memberNameWithEmail(
                                            attachment.account_id
                                        )
                                    "
                                ></span>
                            </div>
                            <div class="attachment-name">
                                <a
                                    :href="
                                        '/attachment/file/' +
                                        attachment.uid +
                                        '/' +
                                        attachment.name
                                    "
                                    target="_blank"
                                    v-text="attachment.name"
                                ></a>
                            </div>
                            <div class="last">
                                <el-button
                                    type="danger"
                                    size="mini"
                                    @click="onAttachmentDelete(attachment.uid)"
                                    >删除</el-button
                                >
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="功能" name="functions">
                    <div>
                        这里的功能，目前只能手工复制去企业微信粘贴发送。以后会做成发邮件通知，或者添加
                        待完成事项。<br /><br />
                    </div>
                    <el-tabs
                        value="create"
                        type="border-card"
                        tab-position="left"
                    >
                        <el-tab-pane label="创建项目" name="create">
                            <el-form label-width="100px">
                                <el-form-item label="项目名"
                                    ><span v-text="name"></span
                                ></el-form-item>
                                <el-form-item label="链接">
                                    <div style="display: flex">
                                        <div>http://</div>
                                        <div>
                                            <el-input
                                                v-model="createForm.name"
                                                placeholder=""
                                            ></el-input>
                                        </div>
                                        <div>.mmler.cn</div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="设计师">
                                    <el-select
                                        v-model="createForm.designerId"
                                        :filterable="true"
                                    >
                                        <el-option
                                            v-for="member of member_list"
                                            :key="member.value"
                                            :value="member.value"
                                            :label="member.label"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item
                                    label=""
                                    v-if="
                                        createForm.name && createForm.designerId
                                    "
                                >
                                    <el-button
                                        type="primary"
                                        @click="createFormSave"
                                        >保存</el-button
                                    >
                                    <span
                                        >点击这个按钮，把以上内容保存到项目资料</span
                                    >
                                </el-form-item>
                                <el-form-item
                                    label=""
                                    v-if="
                                        createForm.name && createForm.designerId
                                    "
                                >
                                    <div>
                                        <div class="create-project-tip">
                                            把以下内容发给 Uzi
                                        </div>
                                    </div>
                                    <div class="create-project">
                                        <p>创建项目</p>
                                        <p><br /></p>
                                        <p>
                                            项目名称:
                                            <span v-text="name"></span>
                                        </p>
                                        <p>
                                            代码仓库:
                                            <span
                                                v-text="createForm.name"
                                            ></span>
                                        </p>
                                        <p>
                                            开发环境:
                                            <span
                                                v-text="
                                                    createForm.name
                                                        ? 'http://' +
                                                          createForm.name +
                                                          '.mmler.cn'
                                                        : ''
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            设计师:
                                            <span
                                                v-text="
                                                    memberNameWithEmail(
                                                        createForm.designerId
                                                    )
                                                "
                                            ></span>
                                        </p>
                                        <p><br /></p>
                                        <p>
                                            1. 安装并激活插件: Elementor,
                                            Elementor Pro, Ele Custom Skin,
                                            Powerpack
                                        </p>
                                        <p>
                                            2. 使用序列号激活插件: Elementor
                                            Pro, Powerpack
                                        </p>
                                        <p>
                                            3.
                                            创建设计师账号，并把账号密码保存到
                                            projects 。
                                            然后私聊设计师，格式如下:
                                        </p>
                                        <p>项目: <span v-text="name"></span></p>
                                        <p>
                                            链接:
                                            <span
                                                v-text="
                                                    createForm.name
                                                        ? 'http://' +
                                                          createForm.name +
                                                          '.mmler.cn/wp-admin/'
                                                        : ''
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            账号:
                                            <span
                                                v-text="
                                                    memberEmail(
                                                        createForm.designerId
                                                    )
                                                "
                                            ></span>
                                        </p>
                                        <p>密码:</p>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="开发评审会" name="meeting">
                            <el-form label-width="100px">
                                <el-form-item label="时间">
                                    <el-date-picker
                                        v-model="meetingForm.datetime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="地点">
                                    <el-select
                                        v-model="meetingForm.meetingroom"
                                    >
                                        <el-option
                                            label="乔布斯"
                                            value="乔布斯"
                                        ></el-option>
                                        <el-option
                                            label="佩奇"
                                            value="佩奇"
                                        ></el-option>
                                        <el-option
                                            label="马斯克"
                                            value="马斯克"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="对接文档">
                                    <el-input
                                        v-model="meetingForm.link"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label=""
                                    v-if="
                                        meetingForm.datetime &&
                                        meetingForm.meetingroom &&
                                        meetingForm.link
                                    "
                                >
                                    <div>
                                        <div class="create-project-tip">
                                            把以下内容发到群里，并 @
                                            相关的人员（如有字段为空，请手动补充完整）
                                        </div>
                                    </div>
                                    <div class="create-project">
                                        <p>
                                            开发需求会 -
                                            <span v-text="name"></span>
                                        </p>
                                        <p><br /></p>
                                        <p>
                                            时间:
                                            <span v-text="meetingtime"></span>
                                        </p>
                                        <p>
                                            地点:
                                            <span
                                                v-text="meetingForm.meetingroom"
                                            ></span>
                                        </p>
                                        <p><br /></p>
                                        <p>项目：<span v-text="name"></span></p>
                                        <p>
                                            策划:
                                            <span
                                                v-text="
                                                    fieldValue('strategist')
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            设计:
                                            <span
                                                v-text="fieldValue('designer')"
                                            ></span>
                                        </p>
                                        <p>
                                            前端:
                                            <span
                                                v-text="fieldValue('frontend')"
                                            ></span>
                                        </p>
                                        <p>
                                            后端:
                                            <span
                                                v-text="fieldValue('backend')"
                                            ></span>
                                        </p>
                                        <p>
                                            测试:
                                            <span
                                                v-text="fieldValue('tester')"
                                            ></span>
                                        </p>
                                        <p>
                                            框架:
                                            <span
                                                v-text="
                                                    fieldValue('framework_url')
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            设计稿:
                                            <span
                                                v-text="
                                                    fieldValue('design_url')
                                                "
                                            ></span>
                                        </p>
                                        <p>
                                            链接:
                                            <span
                                                v-text="fieldValue('dev_url')"
                                            ></span>
                                        </p>
                                        <p><br /></p>
                                        <p>
                                            对接文档：<span
                                                v-text="meetingForm.link"
                                            ></span>
                                        </p>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </el-tab-pane>
                <el-tab-pane label="报表" name="reports">未开发</el-tab-pane>
                <el-tab-pane
                    label="导入"
                    name="import"
                    v-if="$store.state.user.simple_role === 1"
                >
                    <project-importer
                        :fieldList="originalFieldList"
                        :valueList="originalValues"
                        :contracts="contracts"
                        :teams="teams"
                        :members="members"
                        v-if="ready"
                    ></project-importer>
                </el-tab-pane>
                <el-tab-pane label="修改记录" name="logs">
                    <div v-for="log of logs" :key="log.id">
                        <div class="log-item">
                            <div
                                class="log-field log-field-account"
                                v-text="log.account"
                            ></div>
                            <div>在</div>
                            <div
                                class="log-field log-field-time"
                                v-text="log.time"
                            ></div>
                            <div>把</div>
                            <div
                                class="log-field log-field-field"
                                v-text="log.field_label"
                            ></div>
                            <div>修改成</div>
                            <div
                                class="log-field log-field-new_value"
                                v-text="log.new_value"
                            ></div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="ft"></div>
    </div>
</template>

<style lang="less">
.p-project-detail {
    .bd {
        a {
            color: #409eff;
        }
        .group {
            padding: 10px;
        }
        .group-name {
            font-weight: bold;
            font-size: 24px;
        }
        .group-wrap {
            padding-left: 20px;
        }
        .field {
            display: flex;
            padding: 10px 10px;
        }
        .field > div {
            padding-right: 10px;
            border-bottom: 1px solid #eee;
            padding-bottom: 3px;
        }
        .field-name {
            color: #777;
        }
        .field-value {
            flex: 1;
        }
        .field-icons i {
            cursor: pointer;
            color: #777;
        }
        .field-icons i:hover {
            color: #049eff;
        }

        .create-project-tip {
            color: #396;
        }
        .create-project {
            p {
                line-height: 1.5;
            }
        }

        .remark-account {
            color: #409eff;
        }
        .remark-created_at {
            color: #999;
        }

        .attachment-list {
            padding-top: 20px;
        }
        .attachment-item {
            display: flex;
            padding: 10px 0 10px 0;
        }
        .attachment-item:hover {
            background-color: #eee;
        }
        .attachment-item .first {
            width: 200px;
        }
        .attachment-item .attachment-account {
            padding-top: 7px;
            width: 300px;
        }
        .attachment-item .attachment-name {
            padding-top: 7px;
        }
        .attachment-item .attachment-date {
            padding-top: 7px;
        }
        .attachment-item .last {
            padding-left: 20px;
        }

        .log-item {
            color: black;
            display: flex;
            margin-bottom: 10px;
        }
        .log-item .log-field {
            color: #409eff;
            padding-right: 10px;
            padding-left: 10px;
        }
        .log-item .log-field-account {
            padding-left: 0;
        }
    }
}
</style>

<script>
import moment from "moment";
import { ProjectStatus } from "../js/status.js";
import ProjectImporter from "../components/project-importer-1.vue";

// let instance

export default {
    components: { ProjectImporter },
    data() {
        return {
            ready: false,
            isLoading: false,
            originalFieldList: [],
            originalValues: [],
            teams: [],
            members: [],
            contracts: [],
            attachments: [],
            logs: [],
            id: "",
            name: "",
            fields: [
                // {
                // 	group: { key: 'a', name: 'A' },
                // 	list: [ { key: 'a1', name: 'A1', value: 'A1Value' }, { key: 'a2', name: 'A2', value: 'A2Value' } ]
                // },
                // {
                // 	group: { key: 'b', name: 'B' },
                // 	list: [ { key: 'b1', name: 'B1', value: 'B1Value' }, { key: 'b2', name: 'B2', value: 'B2Value' } ]
                // }
            ],
            remarks: [],
            remarksForm: {
                id: "",
                content_id: "",
                remark: "",
            },
            createForm: {
                name: "",
                designerId: "",
            },
            meetingForm: {
                datetime: "",
                meetingroom: "",
                link: "",
            },
        };
    },
    computed: {
        team_list() {
            let arr = [{ label: "请选择", value: "" }];
            for (let i = 0; i < this.teams.length; i++) {
                let team = this.teams[i];
                arr.push({ label: team.name, value: team.id.toString() });
            }
            return arr;
        },
        member_list() {
            let arr = [{ label: "请选择", value: "" }];
            for (let i = 0; i < this.members.length; i++) {
                let member = this.members[i];
                arr.push({
                    label: member.display_name,
                    value: member.id.toString(),
                });
            }
            return arr;
        },
        contract_list() {
            let arr = [{ label: "请选择", value: "" }];
            for (let i = 0; i < this.contracts.length; i++) {
                let contract = this.contracts[i];
                let member = this.members.find(
                    (m) => m.id === contract.salesId
                );
                let customer = this.customers.find(
                    (c) => c.id === contract.customerId
                );
                let label = `${contract.number} - ${member.display_name} - ${customer.name}`;
                arr.push({ label, value: contract.id.toString() });
            }
            return arr;
        },
        meetingtime() {
            if (!this.meetingForm.datetime) {
                return "";
            }
            return moment(this.meetingForm.datetime).format(
                "YYYY-MM-DD HH:mm dddd"
            );
        },
        uploadHeaders() {
            return { "X-CSRF-TOKEN": window._mml_worker_client_token || "" };
        },
        uploadData() {
            return {
                content_id: this.id,
                content_type: "project",
            };
        },
        projectStatusList() {
            let list = ProjectStatus.getFilterList();
            let arr = [];
            list.forEach((item) => {
                arr.push({
                    text: item.text,
                    value: "" + item.value,
                });
            });
            return arr;
        },
    },
    async mounted() {
        // instance = this
        moment.locale("zh_CN");
        await this.getFieldList();
        this.ready = true;
    },
    methods: {
        getFieldList() {
            let that = this;
            return new Promise((resolve, reject) => {
                let id = that.$router.currentRoute.query.id;
                that.id = id;
                that.remarksForm.content_id = id;
                that.$ajax("/project/v2/get_data", { id })
                    .done(function (data) {
                        that.setData(data);
                        resolve(data);
                    })
                    .fail(function () {
                        reject();
                    })
                    .always(function () {
                        //
                    });
            });
        },
        setData(data) {
            // groups: key, name
            // fields: group_key, key, label, type
            // data
            // teams
            // members
            this.originalFieldList = data.fields;
            this.originalValues = data.data;
            this.teams = data.teams;
            this.members = data.members;
            this.contracts = data.contracts;
            this.customers = data.customers;
            this.name = data.name;
            this.remarks = data.remarks;
            this.attachments = data.attachments;
            this.logs = data.logs;

            this.contracts = this.contracts.sort((a, b) => {
                return a.number < b.number ? 1 : -1;
            });
            this.members = this.members.sort((a, b) => {
                return a.display_name < b.display_name ? -1 : 1;
            });

            let contractId = "";
            let list = [];
            for (let i = 0; i < data.groups.length; i++) {
                let group = data.groups[i];
                let groupItem = {};
                groupItem.group = { key: group.key, name: group.name };
                groupItem.list = [];
                for (let j = 0; j < data.fields.length; j++) {
                    let field = data.fields[j];
                    if (field.group_key === group.key) {
                        let fieldItem = {
                            key: field.key,
                            name: field.label,
                            type: field.type,
                            value: undefined,
                            valueStr: "",
                            id: "",
                            editable: true,
                            isEditing: false,
                            tip: field.tip,
                            newValue: "",
                            link: "",
                        };
                        for (let k = 0; k < data.data.length; k++) {
                            let meta = data.data[k];
                            if (meta.field_key === fieldItem.key) {
                                fieldItem.value = meta.value || "";
                                fieldItem.valueStr = this.valueStr(fieldItem);
                                fieldItem.id = meta.id;
                                fieldItem.link = this.getFieldLink(fieldItem);

                                if (
                                    group.key === "info" &&
                                    meta.field_key === "contract_id"
                                ) {
                                    contractId = meta.value;
                                }
                            }
                        }
                        groupItem.list.push(fieldItem);
                    }
                }
                if (group.key === "info" && contractId) {
                    let contract = this.contracts.find(
                        (c) => c.id.toString() === contractId.toString()
                    );
                    groupItem.list.push({
                        key: "sales_name",
                        name: "销售",
                        type: "string",
                        value: "",
                        valueStr:
                            (
                                this.members.find(
                                    (m) =>
                                        m.id.toString() ===
                                        contract.salesId.toString()
                                ) || {}
                            ).display_name || "",
                        id: "",
                        editable: false,
                        isEditing: false,
                        tip: "",
                        newValue: "",
                        link: "",
                    });
                    groupItem.list.push({
                        key: "customer_name",
                        name: "客户",
                        type: "string",
                        value: "",
                        valueStr:
                            (
                                this.customers.find(
                                    (c) =>
                                        c.id.toString() ===
                                        contract.customerId.toString()
                                ) || {}
                            ).name || "",
                        id: "",
                        editable: false,
                        isEditing: false,
                        tip: "",
                        newValue: "",
                        link: "",
                    });
                }
                list.push(groupItem);
            }
            this.fields = list;
        },
        edit(field) {
            field.newValue = field.value;
            field.isEditing = true;
        },
        save(field) {
            let that = this;
            let value = "";
            switch (field.type) {
                case "string":
                case "team":
                case "member":
                case "contract":
                case "project_status":
                case "yes_no":
                    value = field.newValue;
                    break;
                case "month":
                    if (field.newValue) {
                        value = moment(field.newValue).format("YYYY-MM");
                    }
                    break;
                case "date":
                    if (field.newValue) {
                        value = moment(field.newValue).format("YYYY-MM-DD");
                    }
                    break;
                case "datetime":
                    if (field.newValue) {
                        value = moment(field.newValue).format(
                            "YYYY-MM-DD HH:mm"
                        );
                    }
                    break;
                default:
                    break;
            }
            let param = {
                id: field.id,
                content_id: that.$router.currentRoute.query.id,
                field_key: field.key,
                value,
            };
            that.$ajax("/project/v2/update_data", param)
                .done(function () {
                    field.value = value;
                    field.valueStr = that.valueStr(field);
                    field.isEditing = false;
                })
                .fail(function () {
                    //
                })
                .always(function () {
                    //
                });
        },
        cancel(field) {
            field.isEditing = false;
        },
        valueStr(field) {
            // let that = this
            let str = "";
            switch (field.type) {
                case "string":
                case "month":
                case "date":
                case "datetime":
                    str = field.value;
                    break;
                case "team":
                    str =
                        (
                            this.teams.find(
                                (t) => t.id.toString() === field.value
                            ) || {}
                        ).name || "";
                    break;
                case "member":
                    str =
                        (
                            this.members.find(
                                (m) => m.id.toString() === field.value
                            ) || {}
                        ).display_name || "";
                    break;
                case "contract":
                    str =
                        (
                            this.contracts.find(
                                (c) => c.id.toString() === field.value
                            ) || {}
                        ).number || "";
                    break;
                case "project_status":
                    str = ProjectStatus.getText(parseInt(field.value));
                    break;
                case "yes_no":
                    str = field.value === "Y" ? "Yes" : "No";
                    break;
                default:
                    break;
            }
            return str;
        },
        getFieldLink(field) {
            let link = "";
            if (
                field.valueStr &&
                field.valueStr.indexOf &&
                typeof field.valueStr.indexOf === "function" &&
                field.valueStr.indexOf("http") === 0
            ) {
                link = field.valueStr;
            }
            return link;
        },
        addRemark() {
            let that = this;
            if (that.isLoading) {
                return;
            }
            let param = Object.assign({}, that.remarksForm);
            that.isLoading = true;
            that.$ajax("/project/v2/add_remark", param)
                .done(function (ret) {
                    that.remarks = ret;
                    that.remarksForm.remark = "";
                })
                .fail(function () {
                    //
                })
                .always(function () {
                    that.isLoading = false;
                });
        },
        cleanRemark() {
            let content = this.remarksForm.remark;
            let lines = content.replace(/\r/g, "").split("\n");
            let arr = [];
            for (let i = 0; i < lines.length; i++) {
                let line = lines[i];
                arr.push(
                    (["1st payment: ", "2nd payment: ", "3rd payment: "][i] ||
                        "") + line.replace(/\s/g, "")
                );
            }
            this.remarksForm.remark = arr.join("\n");
        },
        createFormSave() {
            let that = this;
            let fieldDesigner = that.findField("designer");
            if (!fieldDesigner) {
                console.log("没找到字段 designer");
                that.$alert("出了点错误。请不要关闭页面，然后联系技术部。");
                return;
            }
            let fieldRepo = that.findField("repo_name");
            if (!fieldRepo) {
                console.log("没找到字段 repo_name");
                that.$alert("出了点错误。请不要关闭页面，然后联系技术部。");
                return;
            }
            let fieldDevLink = that.findField("dev_url");
            if (!fieldDevLink) {
                console.log("没找到字段 dev_url");
                that.$alert("出了点错误。请不要关闭页面，然后联系技术部。");
                return;
            }
            fieldRepo.newValue = that.createForm.name;
            fieldDesigner.newValue = that.createForm.designerId;
            fieldDevLink.newValue = `http://${that.createForm.name}.mmler.cn/`;
            that.save(fieldRepo);
            that.save(fieldDesigner);
            that.save(fieldDevLink);
        },
        onUploadSuccess(res, file, fileList) {
            // console.log('onUploadSuccess')
            // console.log(res)
            // console.log(file)
            /*
name: "000.mp3"
percentage: 100
raw: File
response: {success: false, errmsg: "上传失败。请指定 content_type"}
size: 11232
status: "success"
uid: 1619574923229
				*/
            console.log(fileList);
            if (res.success === true) {
                this.attachments.unshift(res.data);
            } else if (res.success === false) {
                for (let i = 0; i < fileList.length; i++) {
                    let f = fileList[i];
                    if (f.uid === file.uid) {
                        fileList.splice(i, 1);
                    }
                }
                this.$alert(res.errmsg);
            } else {
                this.$alert("未知的上传结果，请刷新页面查看。");
            }
        },
        onUploadError(err /*, file, fileList*/) {
            console.log("onUploadError");
            console.log(err);
            // console.log(file)
            // console.log(fileList)
            this.$alert("上传失败。");
        },
        onAttachmentDelete(uid) {
            let that = this;
            if (!uid) {
                that.$alert("文件id出错: " + uid);
                return;
            }
            let attachment = that.attachments.find((a) => a.uid === uid);
            if (!attachment) {
                that.$alert("文件ID出错: " + uid);
                return;
            }
            if (that.isLoading) {
                that.$alert("正在请求，请稍候");
                return;
            }
            that.$confirm('确定要删除文件 "' + attachment.name + '" 吗？')
                .then(() => {
                    if (that.isLoading) {
                        return;
                    }
                    that.isLoading = true;
                    that.$ajax("/attachment/delete", { uid })
                        .done(function () {
                            let list = [];
                            for (let i = 0; i < that.attachments.length; i++) {
                                let attachment = that.attachments[i];
                                if (attachment.uid === uid) {
                                    continue;
                                }
                                list.push(attachment);
                            }
                            that.attachments = list;
                        })
                        .fail(function () {
                            //
                        })
                        .always(function () {
                            that.isLoading = false;
                        });
                })
                .catch(() => {});
        },
        toHtml(text) {
            return text
                .replace(/\r/g, "")
                .replace(/ /g, "&nbsp;")
                .replace(/\n/g, "<br />");
        },
        memberName(id) {
            let name = "";
            for (let i = 0; i < this.members.length; i++) {
                let member = this.members[i];
                if (member.id.toString() === id.toString()) {
                    name = member.display_name;
                    break;
                }
            }
            return name;
        },
        memberNameWithEmail(id) {
            let name = "";
            if (!id) {
                return name;
            }
            for (let i = 0; i < this.members.length; i++) {
                let member = this.members[i];
                if (member.id.toString() === id.toString()) {
                    name = `${member.display_name} ( ${member.email} )`;
                    break;
                }
            }
            return name;
        },
        memberEmail(id) {
            let email = "";
            if (!id) {
                return email;
            }
            for (let i = 0; i < this.members.length; i++) {
                let member = this.members[i];
                if (member.id.toString() === id.toString()) {
                    email = member.email;
                    break;
                }
            }
            return email;
        },
        fieldValue(key) {
            let value = "";
            for (let i = 0; i < this.fields.length; i++) {
                let group = this.fields[i];
                for (let j = 0; j < group.list.length; j++) {
                    let field = group.list[j];
                    if (field.key === key) {
                        value = this.valueStr(field);
                    }
                }
            }
            return value;
        },
        findField(key) {
            let result = "";
            for (let i = 0; i < this.fields.length; i++) {
                let group = this.fields[i];
                for (let j = 0; j < group.list.length; j++) {
                    let field = group.list[j];
                    if (field.key === key) {
                        result = field;
                    }
                }
            }
            return result;
        },
    },
    filters: {},
};
</script>
