<template>
    <div class="m-project-importer-1" v-loading="isLoading">
        <el-input
            type="textarea"
            v-model="input"
            placeholder="粘贴到这里"
        ></el-input>
        <el-button type="primary" @click="getData">分析</el-button>
        <div>
            <div v-for="field of fields" :key="field.id" class="field-wrap">
                <div v-text="field.id" class="field-item"></div>
                <div v-text="field.key" class="field-item"></div>
                <div v-text="field.label" class="field-item"></div>
                <div v-text="field.type" class="field-item"></div>
                <div v-text="field.oldValue" class="field-item"></div>
                <div v-text="field.newValue" class="field-item new-value"></div>
                <div v-text="field.displayValue" class="field-item"></div>
                <div class="field-item">
                    <el-button
                        type="primary"
                        @click="commit(field.key)"
                        v-show="
                            !field.isCommited &&
                            field.newValue &&
                            field.newValue !== field.oldValue
                        "
                        >提交</el-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.m-project-importer-1 {
    .field-wrap {
        display: flex;
        padding: 10px 0;

        &:hover {
            background-color: #ddd;
        }
    }

    .field-item {
        padding-right: 10px;
        flex: 1;
    }

    .new-value {
        color: red;
    }
}
</style>

<script>
export default {
    components: {},
    props: {
        fieldList: {
            type: Array,
            require: true,
        },
        valueList: {
            type: Array,
            require: true,
        },
        contracts: {
            type: Array,
            require: true,
        },
        members: {
            type: Array,
            require: true,
        },
    },
    data() {
        return {
            isLoading: false,
            input: "",
            fields: [],
        };
    },
    computed: {},
    mounted() {
        for (let i = 0; i < this.fieldList.length; i++) {
            let field = this.fieldList[i];
            let item = {
                id: field.id,
                key: field.key,
                label: field.label,
                type: field.type,
                oldValue: "",
                newValue: "",
                displayValue: "",
                isCommited: false,
            };
            for (let j = 0; j < this.valueList.length; j++) {
                let field = this.valueList[j];
                if (item.key === field.field_key) {
                    item.oldValue = field.value;
                }
            }
            this.fields.push(item);
        }

        // key, label, type
        // field_key, value
    },
    methods: {
        getData() {
            let lines = this.input.replace(/\r/g, "").split("\n");
            for (let i = 0; i < lines.length; i++) {
                let line = lines[i];
                if (i === 2) {
                    this.contractNumber = line.replace(/\s/g, "");
                    this.setValue("contract_id", line.replace(/\s/g, ""));
                    continue;
                } else if (i === 3) {
                    // this.inputSales = line.replace(/\s/g, '')
                } else if (i === 4) {
                    // this.setValue('team_id', line.replace(/^\s|\s$/g, ''))
                    // this.inputTeam = line.replace(/^\s|\s$/g, '')
                } else if (i === 5) {
                    this.setValue("strategist", line.replace(/\s/g, ""));
                    // this.inputPlaner = line.replace(/\s/g, '')
                    continue;
                } else if (i === 6) {
                    this.setValue("designer", line.replace(/\s/g, ""));
                    // this.inputDesigner = line.replace(/\s/g, '')
                    continue;
                } else if (i === 7) {
                    this.setValue("prductions", line.replace(/^\s|\s$/g, ""));
                    // this.product = line.replace(/^\s|\s$/g, '')
                    continue;
                } else if (i === 8) {
                    let value = line.replace(/^\s|\s$/g, "");
                    if (value.indexOf("/") > -1) {
                        this.setValue("factory_location", value.split("/")[0]);
                        this.setValue("factory_count", value.split("/")[1]);
                    } else {
                        this.setValue("factory_location", value);
                    }
                    // this.factoryPostion = line.replace(/^\s|\s$/g, '')
                    continue;
                } else if (i === 9) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("first_call", parts[1].replace(/\s/g, ""));
                    this.setValue("send_files", parts[2].replace(/\s/g, ""));
                    this.setValue(
                        "first_call_send_files_netdays",
                        parts[0].replace(/\s/g, "")
                    );
                    continue;
                } else if (i === 10) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("cloth_notice", parts[1].replace(/\s/g, ""));
                    this.setValue("cloth_confirm", parts[2].replace(/\s/g, ""));
                    this.setValue("cloth_netdays", parts[0].replace(/\s/g, ""));
                    continue;
                } else if (i === 11) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue(
                        "research_start",
                        parts[1].replace(/\s/g, "")
                    );
                    this.setValue("research_end", parts[2].replace(/\s/g, ""));
                    this.setValue(
                        "research_netdays",
                        parts[0].replace(/\s/g, "")
                    );
                    continue;
                } else if (i === 12) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("ask_questions", parts[1].replace(/\s/g, ""));
                    this.setValue(
                        "fremework_finish",
                        parts[2].replace(/\s/g, "")
                    );
                    this.setValue(
                        "fremework_netdays",
                        parts[0].replace(/\s/g, "")
                    );
                    continue;
                } else if (i === 13) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue(
                        "fremework_approving",
                        parts[1].replace(/\s/g, "")
                    );
                    this.setValue(
                        "fremework_approved",
                        parts[2].replace(/\s/g, "")
                    );
                    this.setValue(
                        "fremework_approve_netdays",
                        parts[0].replace(/\s/g, "")
                    );
                    continue;
                } else if (i === 14) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue(
                        "fremework_send",
                        parts[1].replace(/\s/g, "")
                    );
                    this.setValue(
                        "fremework_confirm",
                        parts[2].replace(/\s/g, "")
                    );
                    this.setValue(
                        "fremework_confirm_netdays",
                        parts[0].replace(/\s/g, "")
                    );
                    continue;
                } else if (i === 15) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue(
                        "send_photo_plan",
                        parts[1].replace(/\s/g, "")
                    );
                    this.setValue(
                        "photo_date_end",
                        parts[2].replace(/\s/g, "")
                    );
                    // this.setValue('fremework_confirm_netdays', parts[0].replace(/\s/g, ''))
                    continue;
                } else if (i === 16) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue(
                        "framework_ldp_start",
                        parts[1].replace(/\s/g, "")
                    );
                    this.setValue(
                        "framework_ldp_finish",
                        parts[2].replace(/\s/g, "")
                    );
                    this.setValue(
                        "framework_ldp_netdays",
                        parts[0].replace(/\s/g, "")
                    );
                    continue;
                } else if (i === 17) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("logo_start", parts[1].replace(/\s/g, ""));
                    this.setValue("logo_end", parts[2].replace(/\s/g, ""));
                    this.setValue("logo_netdays", parts[0].replace(/\s/g, ""));
                    continue;
                } else if (i === 18) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("logo_send", parts[1].replace(/\s/g, ""));
                    this.setValue("logo_confirm", parts[2].replace(/\s/g, ""));
                    this.setValue(
                        "logo_confirm_netdays",
                        parts[0].replace(/\s/g, "")
                    );
                    continue;
                } else if (i === 19) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("home_start", parts[1].replace(/\s/g, ""));
                    this.setValue("home_end", parts[2].replace(/\s/g, ""));
                    this.setValue("home_netdays", parts[0].replace(/\s/g, ""));
                    continue;
                } else if (i === 20) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("home_send", parts[1].replace(/\s/g, ""));
                    this.setValue("home_confirm", parts[2].replace(/\s/g, ""));
                    this.setValue(
                        "home_confirm_netdays",
                        parts[0].replace(/\s/g, "")
                    );
                    continue;
                } else if (i === 21) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("page_start", parts[1].replace(/\s/g, ""));
                    this.setValue("page_end", parts[2].replace(/\s/g, ""));
                    this.setValue("page_netdays", parts[0].replace(/\s/g, ""));
                    continue;
                } else if (i === 22) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue(
                        "page_approving",
                        parts[1].replace(/\s/g, "")
                    );
                    this.setValue("page_approved", parts[2].replace(/\s/g, ""));
                    this.setValue(
                        "page_approve_netdays",
                        parts[0].replace(/\s/g, "")
                    );
                    continue;
                } else if (i === 23) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("page_send", parts[1].replace(/\s/g, ""));
                    this.setValue("page_confirm", parts[2].replace(/\s/g, ""));
                    this.setValue(
                        "page_confirm_netdays",
                        parts[0].replace(/\s/g, "")
                    );
                    continue;
                } else if (i === 24) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("ldp_start", parts[1].replace(/\s/g, ""));
                    this.setValue("ldp_end", parts[2].replace(/\s/g, ""));
                    this.setValue("ldp_netdays", parts[0].replace(/\s/g, ""));
                    continue;
                } else if (i === 25) {
                    console.log("开发需求会", line);
                    // let parts = line.split(String.fromCharCode(9))
                    // this.setValue('ldp_start', parts[1].replace(/\s/g, ''))
                    // this.setValue('ldp_end', parts[2].replace(/\s/g, ''))
                    // this.setValue('ldp_netdays', parts[0].replace(/\s/g, ''))
                    continue;
                } else if (i === 26) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue(
                        "frontend_start",
                        parts[1].replace(/\s/g, "")
                    );
                    this.setValue("frontend_end", parts[2].replace(/\s/g, ""));
                    // this.setValue('', parts[0].replace(/\s/g, ''))
                    continue;
                } else if (i === 27) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("backend_start", parts[1].replace(/\s/g, ""));
                    this.setValue("backend_end", parts[2].replace(/\s/g, ""));
                    // this.setValue('', parts[0].replace(/\s/g, ''))
                    continue;
                } else if (i === 28) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue("test_start", parts[1].replace(/\s/g, ""));
                    this.setValue("test_end", parts[2].replace(/\s/g, ""));
                    // this.setValue('', parts[0].replace(/\s/g, ''))
                    continue;
                } else if (i === 29) {
                    let parts = line.split(String.fromCharCode(9));
                    this.setValue(
                        "website_confirm",
                        parts[1].replace(/\s/g, "")
                    );
                    // this.setValue('test_end', parts[2].replace(/\s/g, ''))
                    // this.setValue('', parts[0].replace(/\s/g, ''))
                    continue;
                } else if (i === 32) {
                    // this.setValue('framework_url', line.replace(/\s/g, ''))
                    console.log(line);
                    continue;
                } else if (i === 33) {
                    this.setValue("framework_url", line.replace(/\s/g, ""));
                    continue;
                } else if (i === 34) {
                    this.setValue("design_url", line.replace(/\s/g, ""));
                    continue;
                } else if (i === 35) {
                    this.setValue("online_url", line.replace(/\s/g, ""));
                    continue;
                }
                console.log(`第 ${i} 行:`);
                console.log(line);
            }
        },
        setValue(key, value) {
            let field = this.fields.find((f) => f.key === key);
            if (!field) {
                alert(`字段 ${key} 找不到`);
                return;
            }
            // if (!value) {
            // 	console.log(value)
            // 	alert(`${key} 没有值`)
            // 	// value = ''
            // }
            if (typeof value !== "string") {
                // value = ''
                console.log(value);
                alert(`${key} 不是字符串`);
            }
            if (field.type === "contract") {
                let item = this.contracts.find((c) => c.number === value);
                if (!item) {
                    alert(`未能找到合同号 ${value}`);
                    return;
                }
                field.newValue = item.id.toString();
                field.displayValue = item.number;
            } else if (field.type === "team") {
                // let item = this.teams.find(t => console.log(t))
                // if (!item) {
                // 	alert(`未能找到合同号 ${ value }`)
                // 	return
                // }
                // field.newValue = item.id.toString()
                // field.displayValue = item.number
            } else if (field.type === "member") {
                let item = this.members.find(
                    (m) => m.display_name.indexOf(value) === 0
                );
                if (!item) {
                    alert(`未能找到成员 ${value}`);
                    return;
                }
                field.newValue = item.id.toString();
                field.displayValue = item.display_name;
            } else if (field.type === "date" || field.type === "datetime") {
                if (!value) {
                    return;
                }
                let parts = value.split("-");
                if (parts.length < 3) {
                    alert("日期格式不对 " + key + ": " + value);
                    return;
                }
                if (parts[0].length !== 4) {
                    alert("日期格式不对: " + key + " (年) " + value);
                    return;
                }
                field.newValue =
                    parts[0] +
                    "-" +
                    (parts[1].length < 2 ? "0" + parts[1] : parts[1]) +
                    "-" +
                    (parts[2].length < 2 ? "0" + parts[2] : parts[2]);
            } else if (field.type === "string") {
                field.newValue = value;
                // field.displayValue = value
            }
            // console.log(JSON.stringify(field))
        },
        commit(key) {
            let that = this;
            let field = this.fields.find((f) => f.key === key);
            if (!field) {
                alert(`字段 ${key} 找不到`);
                return;
            }
            let param = {
                id: field.id,
                content_id: that.$router.currentRoute.query.id,
                field_key: field.key,
                value: field.newValue,
            };
            if (that.isLoading) {
                return;
            }
            that.isLoading = true;
            that.$ajax("/project/v2/update_data", param)
                .done(function () {
                    // field.value = value
                    // field.valueStr = that.valueStr(field)
                    // field.isEditing = false
                    // that.$alert('done')
                    field.isCommited = true;
                })
                .fail(function () {
                    // that.$alert('fail')
                })
                .always(function () {
                    that.isLoading = false;
                });
        },
    },
    filters: {},
};
</script>
